<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.notificationsByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="notificationsHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="notifications"
            :search="searchNotificationString"
            class="elevation-1 cursor-pointer"
            @click:row="openDriving"
            multi-sort>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.notifications') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchNotificationString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { notificationsHeaders } from '@/mixins/data-table-headers';
import router from '@/router';
import { mapState } from 'vuex';

export default {
  name: 'Notifications',
  data: () => ({
    user: {},
    searchNotificationString: '',
    notificationDialog: false,
  }),
  created() {
    this.loadUser();
    this.$store.dispatch('notifications/getAllNotifications');
  },
  computed: {
    ...mapState('notifications', ['notifications']),
    notificationsHeaders() {
      return notificationsHeaders(i18n);
    },
  },
  methods: {
    loadUser() {
      this.user = this.$store.getters['auth/user'];
    },

    openDriving(notification) {
      if (notification.data.type !== 'vehicle-out-of-order') {
        router.push({ name: 'CalendarView', query: { driving_id: notification.data.driving_id } });
      }
    },
  },

  watch: {},
};
</script>
