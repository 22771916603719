<template>
  <v-card-text>
    <v-container>
      <v-row>
        <v-col v-if="client.itemImage" cols="12" lg="12" md="12" sm="12" class="my-2">
          <img :src="client.itemImage" alt="Profile Image" class="profile__image" />
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
          <span class="bold">
            {{ $t('clientsPage.name') }}
          </span>
          <span>
            {{ client.name || '/' }}
          </span>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
          <span class="bold">
            {{ $t('clientsPage.address') }}
          </span>
          <span>
            {{ client.address || '/' }}
          </span>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
          <span class="bold">
            {{ $t('clientsPage.phoneNumber') }}
          </span>
          <span>
            {{ client.phone || '/' }}
          </span>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
          <span class="bold">
            {{ $t('clientsPage.email') }}
          </span>
          <span>
            {{ client.email || '/' }}
          </span>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
          <span class="bold">
            {{ $t('clientsPage.clientType') }}
          </span>
          <span>
            {{ getTranslatedType(client.type) || '/' }}
          </span>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
          <span class="bold">
            {{ $t('clientsPage.companyId') }}
          </span>
          <span>
            {{ client.pib || '/' }}
          </span>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
          <span class="bold">
            {{ $t('clientsPage.percentage') }}
          </span>
          <span>
            {{ client.percent || '/' }}
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
</template>

<script>
import i18n from '@/i18n/i18n';

export default {
  name: 'ClientGeneralInfo',
  props: ['client'],
  data: () => ({}),
  methods: {
    getTranslatedType(type) {
      const roleTranslations = {
        natural_person: 'clientsPage.individual',
        legal_entity: 'clientsPage.business',
      };

      return roleTranslations[type] ? i18n.t(roleTranslations[type]) : '';
    },
  },
};
</script>

<style scoped lang="scss">
.profile__image {
  height: 200px;
  width: auto;
}

.info__wrapper {
  span {
    display: block;

    color: #1b1d20;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */

    &.bold {
      color: #444;

      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 88.889% */
      margin-bottom: 1rem;
    }
  }
}
</style>
