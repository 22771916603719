<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.cardsByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="cardsHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="cards"
            :search="searchCardString"
            class="elevation-1 cursor-pointer"
            multi-sort>
            <template v-slot:top>
              <v-toolbar flat>
                <v-text-field
                  v-model="searchCardString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer></v-spacer>

                <v-dialog v-model="cardDialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(cardFormTitle) }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="closeCardEdit"></v-icon>
                    </v-card-title>

                    <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(saveCard)">
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="required|alpha_spaces"
                                  v-slot="{ errors }"
                                  name="card_holder_name">
                                  <v-text-field
                                    v-model="editedItem.card_holder_name"
                                    clearable
                                    :label="$t('customerCardsPage.cardholderName')"
                                    type="text"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="required|credit_card"
                                  v-slot="{ errors }"
                                  name="card_number">
                                  <v-text-field
                                    v-model="editedItem.card_number"
                                    clearable
                                    :label="$t('customerCardsPage.cardNumber')"
                                    type="text"
                                    @input="onCardNumberInput"
                                    v-mask="cardNumberMask"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="required|date_format:MM/YY|date_in_past"
                                  v-slot="{ errors }"
                                  name="expiry_date">
                                  <v-text-field
                                    v-model="editedItem.expiry_date"
                                    clearable
                                    :label="$t('customerCardsPage.expiryDate')"
                                    type="text"
                                    v-mask="'##/##'"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="required|numeric|min:3|max:4"
                                  v-slot="{ errors }"
                                  name="cvv">
                                  <v-text-field
                                    v-model="editedItem.cvv"
                                    clearable
                                    :label="$t('customerCardsPage.cvv')"
                                    type="text"
                                    v-mask="'####'"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="cardDialogDelete" max-width="650">
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t('customerCardsPage.deleteCard') }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="closeCardDelete"></v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <p>
                          {{ $t('customerCardsPage.deleteMessage') }}
                        </p>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn :disabled="!enableCardSave" class="primary" text @click="deleteCardItemConfirm">
                        {{ $t('buttons.yes') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.actions') }}
                    </span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="deleteItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.delete') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.delete') }}</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { cardsHeaders } from '@/mixins/data-table-headers';
import { defaultCard } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';

export default {
  name: 'CardItem',
  components: { ButtonSubmit },
  props: ['cards', 'clientId'],
  data: () => ({
    searchCardString: '',
    cardDialog: false,
    cardDialogDelete: false,
    cardFormTitle: 'customerCardsPage.newItem',
    editedItem: {},
    editedItemDeleted: {},
    editedItemIndex: null,
    enableCardSave: true,
    loading: false,
    cardNumberMask: '#### #### #### #### ###', // Default to 19 digits
  }),
  created() {
    this.editedItem = Object.assign({}, defaultCard);
  },
  computed: {
    cardsHeaders() {
      return cardsHeaders(i18n);
    },
  },
  methods: {
    editCardItem(item) {
      this.editedItem = this.cards.indexOf(item);
      this.editedItemIndex = this.cards.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.cardFormTitle = 'customerCardsPage.editItem';
      this.editedItem.card_holder_name = item.card_holder_name ? item.card_holder_name : null;
      this.editedItem.card_number = item.card_number ? item.card_number : null;
      this.editedItem.expiry_date = item.expiry_date ? item.expiry_date : null;
      this.editedItem.cvv = item.cvv ? item.cvv : null;
      this.cardDialog = true;
    },

    deleteItem(item) {
      this.editedItemDeleted = this.cards.indexOf(item);
      this.editedItemDeleted = Object.assign({}, item);
      this.cardDialogDelete = true;
    },

    async saveCard() {
      this.enableCardSave = false;
      this.loading = true;
      let path = 'saveCard';

      this.editedItem.card_holder_name = this.editedItem.card_holder_name ? this.editedItem.card_holder_name : null;
      this.editedItem.card_number = this.editedItem.card_number ? this.editedItem.card_number : null;
      this.editedItem.expiry_date = this.editedItem.expiry_date ? this.editedItem.expiry_date : null;
      this.editedItem.cvv = this.editedItem.cvv ? this.editedItem.cvv : null;
      this.editedItem.client_id = this.clientId ? this.clientId : null;

      await this.$store
        .dispatch('customerCards/' + path, this.editedItem)
        .then((res) => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.$emit('addCard', this.clientId, res.data);
          this.closeCardEdit();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableCardSave = true;
          this.loading = false;
          this.cardFormTitle = 'customerCardsPage.newItem';
        });
    },

    async deleteCardItemConfirm() {
      await this.$store
        .dispatch('customerCards/deleteCard', this.editedItemDeleted)
        .then((res) => {
          if (res.success) {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.$emit('removeCard', this.clientId, this.editedItemDeleted.id);
            this.closeCardDelete();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closeCardDelete();
        });
    },

    closeCardEdit() {
      this.cardDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultCard);
      });
      this.$refs.form.reset();
      this.cardFormTitle = 'customerCardsPage.newItem';
    },

    closeCardDelete() {
      this.cardDialogDelete = false;
    },

    onCardNumberInput() {
      const value = this.editedItem.card_number.replace(/\s+/g, '');
      const firstDigits = value.slice(0, 6);

      if (/^3[47]/.test(firstDigits)) {
        // American Express: 15 digits
        this.cardNumberMask = '#### ###### #####';
      } else if (/^3(0[0-5]|[68])/.test(firstDigits)) {
        // Diners Club: 14 digits
        this.cardNumberMask = '#### #### #### ##';
      } else if (/^4/.test(firstDigits)) {
        // Visa: 16 digits
        this.cardNumberMask = '#### #### #### ####';
      } else if (/^5[1-5]/.test(firstDigits) || /^2(2[2-9][1-9]|[3-7]\d{2})/.test(firstDigits)) {
        // MasterCard: 16 digits
        this.cardNumberMask = '#### #### #### ####';
      } else if (/^6(011|5|4[4-9])/.test(firstDigits)) {
        // Discover: 16 digits
        this.cardNumberMask = '#### #### #### ####';
      } else {
        // Default mask for other cards (up to 19 digits)
        this.cardNumberMask = '#### #### #### #### ###';
      }
    },
  },

  watch: {
    cardDialog(val) {
      val || this.closeCardEdit();
    },
  },
};
</script>
