<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.clientsByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="clientsPageHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="clients"
            :search="searchString"
            :item-class="addCustomClass"
            class="elevation-1"
            @click:row="showClientDetailsDialog"
            multi-sort>
            <template v-slot:item.type="{ item }">
              {{ getTranslatedType(item.type) }}
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ $t('tables.clients') }} </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer></v-spacer>

                <v-dialog v-model="dialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(formTitle) }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="close"></v-icon>
                    </v-card-title>

                    <clients-form
                      ref="clientForm"
                      :editedItem="editedItem"
                      @loadAllClients="loadAllClients"
                      @closeClientEdit="close" />
                  </v-card>
                </v-dialog>

                <v-dialog v-model="clientDetailsDialog" max-width="700px">
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(formTitle) }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="closeClientDetailsModal"></v-icon>
                    </v-card-title>

                    <template>
                      <v-tabs v-model="clientTab">
                        <v-tab>{{ $t('clientsPage.tabs.general') }}</v-tab>
                        <v-tab>{{ $t('clientsPage.tabs.transfers') }}</v-tab>
                        <v-tab>{{ $t('clientsPage.tabs.users') }}</v-tab>
                        <v-tab>{{ $t('clientsPage.tabs.cards') }}</v-tab>
                      </v-tabs>

                      <v-tabs-items v-model="clientTab">
                        <v-tab-item key="1">
                          <client-general-info :client="client" />
                        </v-tab-item>
                        <v-tab-item key="2">
                          <drivings-table :drivings="client.drivings" :key="client.id" />
                        </v-tab-item>
                        <v-tab-item key="3">
                          <users-page :clientId="client.id" />
                        </v-tab-item>
                        <v-tab-item key="4">
                          <customer-cards
                            :cards="client.paymentCards"
                            :clientId="client.id"
                            :key="client.id"
                            @addCard="addCard"
                            @removeCard="removeCard" />
                        </v-tab-item>
                      </v-tabs-items>
                    </template>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.actions') }}
                    </span>
                  </v-tooltip>
                </template>

                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="showClientDetailsDialog(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-arrow-expand</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.show') }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="editItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs" v-on="on"> mdi-pencil </v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.edit') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="makeClientActive(item)">
                        <v-icon v-if="item.active" class="mx-1" small v-bind="attrs">mdi-toggle-switch-outline</v-icon>
                        <v-icon v-if="!item.active" class="mx-1" small v-bind="attrs"
                          >mdi-toggle-switch-off-outline</v-icon
                        >
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ item.active ? $t('buttons.deactivate') : $t('buttons.activate') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ item.active ? $t('tooltips.deactivate') : $t('tooltips.activate') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="showDeleteClientModal(item)">
                        <v-icon class="mx-1" color="red darken-3" small v-bind="attrs" v-on="on">
                          mdi-trash-can-outline
                        </v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.delete') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.delete') }}</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-dialog v-model="deleteClientModal" max-width="650">
        <v-card>
          <v-card-title class="info title white--text font-weight-bold">
            {{ $t('clientsPage.deleteClient') }}
            <v-spacer></v-spacer>
            <v-icon class="mdi mdi-close" style="color: white" @click="closeDeleteClientModal"></v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <p>
                {{ $t('clientsPage.deleteMessage') }} <b>{{ userName }}</b
                >?
              </p>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!enableDelete" class="primary" text @click="deleteClient">
              {{ $t('buttons.yes') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { clientsPageHeaders } from '@/mixins/data-table-headers';
import { defaultClient } from '@/mixins/default-items';
import ClientsForm from '@/components/clients/ClientsForm.vue';
import ClientGeneralInfo from '@/components/clients/ClientGeneralInfo.vue';
import DrivingsTable from '@/components/customers/drivings/CustomersDrivingsTab.vue';
import CustomerCards from '@/components/customers/CustomerCardsTab.vue';
import UsersPage from '@/views/pages/UsersPage.vue';
import { formatDateTime } from '@/utils/formatDate';

export default {
  name: 'Clients',
  components: { ClientsForm, ClientGeneralInfo, DrivingsTable, CustomerCards, UsersPage },
  data: () => ({
    searchString: '',
    dialog: false,
    clients: [],
    formTitle: 'clientsPage.newItem',
    formValid: true,
    editedItem: {},
    editedItemDeleted: {},
    deletedItem: {},
    editedIndex: -1,
    enableDelete: true,
    deleteClientModal: false,
    userName: '',
    client: {},
    clientDetailsDialog: false,
    clientTab: null,
  }),
  created() {
    this.editedItem = Object.assign({}, defaultClient);
    this.loadAllClients();
  },
  computed: {
    clientsPageHeaders() {
      return clientsPageHeaders(i18n);
    },
  },
  methods: {
    async loadAllClients() {
      await this.$store.dispatch('clients/getAllClients').then((res) => {
        this.clients = res.data.map((e) => {
          e.active = parseInt(e.active);

          e.drivings.map((driving) => {
            driving.pickup_time = formatDateTime(driving.pickup_time);
          });

          return e;
        });
      });
    },

    editItem(item) {
      this.editedItem = this.clients.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.name = item.name;
      this.editedItem.address = item?.address;
      this.editedItem.phone = item?.phone;
      this.editedItem.email = item?.email;
      this.editedItem.company_id = item?.pib;
      this.editedItem.type = item?.type;
      this.editedItem.percentage = item?.percent;
      this.formTitle = `${this.editedItem.name}`;
      this.dialog = true;
    },

    deleteItem(item) {
      this.deletedItem = this.clients.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.formTitle = item.name;
      this.dialog = true;
    },

    async deleteClient() {
      this.enableDelete = false;

      await this.$store
        .dispatch('clients/deleteClient', this.editedItemDeleted)
        .then((res) => {
          if (res.success) {
            this.loadAllClients();
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.closeDeleteClientModal();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.enableDelete = true;
        });
    },

    async makeClientActive(item) {
      await this.$store
        .dispatch('clients/changeClientActiveStatus', item)
        .then((res) => {
          if (res.data.active) {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.activeStatusChanged'), color: 'green' });
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.activeStatusChanged'), color: 'green' });
          }
          item.active = !item.active;
        })
        .catch(() => {
          item.active = !item.active;
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultClient);
        this.editedIndex = -1;
      });
      this.$refs.clientForm.resetForm();
      this.formTitle = 'clientsPage.newItem';
    },

    showDeleteClientModal(item) {
      this.editedItemDeleted = item;
      this.userName = item?.name;
      this.deleteClientModal = true;
    },

    closeDeleteClientModal() {
      this.deleteClientModal = false;
    },

    showClientDetailsDialog(item) {
      this.client = item;
      this.formTitle = item.name;
      this.clientDetailsDialog = true;
    },

    closeClientDetailsModal() {
      this.clientDetailsDialog = false;
      this.clientTab = null;
      this.formTitle = 'clientsPage.newItem';
    },

    getTranslatedType(type) {
      const roleTranslations = {
        natural_person: 'clientsPage.individual',
        legal_entity: 'clientsPage.business',
      };

      return roleTranslations[type] ? i18n.t(roleTranslations[type]) : '';
    },

    addCard(clientId, newCard) {
      // Find the client by id
      const client = this.clients.find((item) => item.id === clientId);

      // Add the card
      client.paymentCards.unshift(newCard);
    },

    removeCard(clientId, cardId) {
      // Find the client by id
      const client = this.clients.find((item) => item.id === clientId);

      // Find the index of the payment card by id
      const paymentCardIndex = client.paymentCards.findIndex((paymentCard) => paymentCard.id === cardId);

      if (paymentCardIndex !== -1) {
        // Remove the payment item from the paymentCards array
        client.paymentCards.splice(paymentCardIndex, 1);
      }
    },

    addCustomClass(item) {
      return item.active === true || item.active == 1 ? 'active-row' : 'inactive-row';
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    clientDetailsDialog(val) {
      val || this.closeClientDetailsModal();
    },
  },
};
</script>
